

























import {Vue, Component, Prop} from 'vue-property-decorator';
import { Validation } from 'vuelidate';

import FormField from '@/shared/resources/components/forms/FormField.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';

@Component({
  components: {
    InputField,
    FormField,
  },
})
export default class ContactDataFieldset extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;
  @Prop() private validation!: Validation;
}
